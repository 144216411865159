import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Title, Section, Text, Box, Button } from '../../components/Core'
import { breakpoints } from "../../utils";
import styled from 'styled-components'
import { Link } from 'gatsby'

const ImageShadow = styled(Box)`
  @media (max-width: ${breakpoints.lg}px) {
    display: none;
  }

  img {
    border-radius: 10px;
    -webkit-filter: drop-shadow(
      ${({ theme }) => `0 52px 42px ${theme.colors.shadow}`}
    );
    filter: drop-shadow(${({ theme }) => `0 52px 42px ${theme.colors.shadow}`});
  }
`

const ListItem = styled.li`
  font-size: 21px;
  font-weight: 300;
  padding-top: 0px;
  padding-bottom: 12px;
  padding-left: 8px !important;
`

const Hero = ({title, firstP, secondP, boldP, img, imgSrc, bullets, children, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  
  // COLORS
  const PARAGRAPH_COLOR = userDarkMode ? 'lightGray' : 'black'
  
  return (
    <>
      <Section bg={userDarkMode ? 'black' : 'bg'} className="mt-5" pb={[5, null, null, 5]} {...rest}>
        <Container>
          <Row>
            <Col
             
             data-aos="fade-left"
             data-aos-duration="750"
             data-aos-once="true"
             data-aos-delay="500"
             className='mr-xl-3 mr-lg-2 '>
              <Title
                variant="hero-home"
                color={userDarkMode ? '#3986F7' : '#185398'}
              >
                {title}
              </Title>
              <Text
                variant="strong"
                color={userDarkMode ? 'lightShade' : 'darkShade'}
              >
                {children}
              </Text>
              <Text className="mt-4" color={userDarkMode ? '#FAFAFA' : 'dark'}>
                {firstP}
              </Text>
              <Text className="mt-4" color={userDarkMode ? '#FAFAFA' : 'dark'}>
                {secondP}
              </Text>
              <Text
                className="mt-4"
                style={{ fontWeight: 'bold' }}
                color={userDarkMode ? '#FAFAFA' : 'dark'}
              >
                {boldP}
              </Text>
              {bullets && (
                <ul
                  className="ml-5 mt-3 mb-2"
                  style={{ color: PARAGRAPH_COLOR }}
                >
                  {rest.bullet1 && <ListItem type="disc">{rest.bullet1}</ListItem>}
                  {rest.bullet2 && <ListItem type="disc">{rest.bullet2}</ListItem>}
                  {rest.bullet3 && <ListItem type="disc">{rest.bullet3}</ListItem>}
                  {rest.bullet4 && <ListItem type="disc">{rest.bullet4}</ListItem>}
                  {rest.bullet5 && <ListItem type="disc">{rest.bullet5}</ListItem>}
                  {rest.bullet6 && <ListItem type="disc">{rest.bullet6}</ListItem>}
                </ul>
              )}
              {
              rest.internalLink && (
                  <Button className="mt-1">
                    <Link to={rest.internalUrl}>
                      <Text color="#FAFAFA">
                        {rest.internalText}
                      </Text>
                    </Link>
                  </Button>
                )
                }
                {
                  rest.externalLink && (
                    <Button className="mt-1">
                      <a target="_blank" href={rest.externalUrl}>
                        <Text color="#FAFAFA">
                          {rest.externalText}
                        </Text>
                      </a>
                    </Button>
                  )
                }
                {
                  rest.scrollButton && (
                    <Button onClick={rest.scrollOnClick} className="mt-1">
                        <Text color="#FAFAFA">
                          {rest.scrollText}
                        </Text>
                    </Button>
                  )
                }
            </Col>
            {
              img && ( 
              <Col lg="5">
                <ImageShadow
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                >
                  <img
                    width="100%"
                    height="auto"
                    alt=""
                    className="img-fluid"
                    src={imgSrc}
                  />
                </ImageShadow>
              </Col>
            )}
          </Row>
        </Container>
      </Section>
    </>
  )
}
export default Hero
